import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { indexStore } from "../../store/indexStore";
import BottomSheet from "../ButtomSheet/BottomSheet";
import { useTheme } from "next-themes";
import { SheetContent, SheetHeader, SheetTitle } from "../ui/sheet";

const LangSelect = (props) => {
  const {
    changeLang,
    lang,
    changeLangBottomSheet,
    langOpenBottomSheet,
    style,
  } = indexStore();
  const { i18n } = useTranslation();
  const [toogleSwitch, setToogleSwitch] = useState(lang === "ar");
  const { t } = useTranslation();

  useEffect(() => {
    const handleLanguageChange = () => {
      i18n.changeLanguage(lang);
      document.documentElement.dir = i18n.dir();
      setToogleSwitch(lang === "ar");
    };

    if (lang) {
      handleLanguageChange();
    } else {
      changeLang(localStorage.getItem("Lang") || "en");
    }
  }, [lang]);

  const onChangeToogle = (e) => {
    setToogleSwitch(!toogleSwitch);
    changeLang(e ? "ar" : "en");
    changeLangBottomSheet(false);
  };
  return (
    <SheetContent
      side={"bottom"}
      style={{ background: style.background }}
      className="flex flex-col bg-background max-h-[60vh] overflow-y-auto z-50 "
    >
      <SheetHeader>
        <SheetTitle>
          <h2 style={{ color: style.onBackground }} className="text-xl font-bold mb-4">{t("gloabl.changeLang")}</h2>
        </SheetTitle>
      </SheetHeader>
      <div className="flex flex-col justify-around gap-5 text-onBackground">
        <p
          onClick={() => onChangeToogle(true)}
          className={`text-lg font-semibold p-2 cursor-pointer ${
            lang == "ar" ? `bg-primary text-onPrimary` : ""
          }`}
          style={{
            background: lang == "ar" ? style.primary : "transparent",
            color: lang == "ar" ? style.onPrimary : style.onBackground,
          }}
        >
          العربية
        </p>
        <p
          onClick={() => onChangeToogle(false)}
          className={`text-lg font-semibold p-2 cursor-pointer ${
            lang == "en" ? `bg-primary text-onPrimary` : ""
          }`}
          style={{
            background: lang == "en" ? style.primary : "transparent",
            color: lang == "en" ? style.onPrimary : style.onBackground,
          }}
        >
          English
        </p>
      </div>
    </SheetContent>
  );
};

export default LangSelect;
